import DateComponent from '@/components/Date/DateComponent.vue';
import NAWComponent from '@/components/Naw/NAWComponent.vue';
import PaymentComponent from '@/components/Payment/PaymentComponent.vue';
import TicketComponent from '@/components/Ticket/TicketComponent.vue';
import {
  ADMIN_ROUTES,
  BUSINESS_ROUTES,
  GIFTCARD_ROUTES,
  NOCTURNES_ROUTES,
  TICKET_ROUTES,
  WORKSHOP_ROUTES
} from '@/constants/routeNames';
import GiftcardView from '@/views/GiftCardView.vue';
import AdminView from '@/views/AdminView.vue';
import SelectComponent from '@/components/Select/SelectComponent.vue';
import WorkshopView from '@/views/WorkshopView.vue';
import BusinessesView from '@/views/BusinessesView.vue';
import Vue from 'vue';
import Router from 'vue-router';
import LoginComponent from '@/components/Login/LoginComponent.vue';
import CompleteComponent from '@/components/Complete/CompleteComponent.vue';
import AdminComponent from '@/components/Admin/AdminComponent.vue';
import ArticleComponent from '@/components/Articles/ArticleComponent.vue';
import TicketsView from '@/views/TicketsView.vue';
import NocturnesView from '@/views/NocturnesView.vue';

Vue.use(Router);

export const FLOW_SHARED = [
  {
    path: '/',
    name: GIFTCARD_ROUTES.ROUTE_TICKETS,
    component: TicketComponent,
    meta: {
      name: 'header.selectItems',
    },
  },
  {
    path: 'artikelen',
    name: GIFTCARD_ROUTES.ROUTE_ARTICLES,
    component: ArticleComponent,
    meta: {
      name: 'header.articles',
    },
  },
  {
    path: 'gegevens',
    name: GIFTCARD_ROUTES.ROUTE_NAW,
    component: NAWComponent,
    meta: {
      name: 'header.personalDetails',
    },
  },
  {
    path: 'betalen',
    name: GIFTCARD_ROUTES.ROUTE_PAYMENT,
    component: PaymentComponent,
    meta: {
      name: 'header.payment',
    },
  },
];

export const FLOW_NORMAL = [
  {
    path: '/',
    name: TICKET_ROUTES.ROUTE_TICKETS,
    component: TicketComponent,
    meta: {
      name: 'header.selectItems',
    },
  },
  {
    path: 'datum-tijd',
    name: TICKET_ROUTES.ROUTE_DATE,
    component: DateComponent,
    meta: {
      name: 'header.dateTime',
    },
  },
  {
    path: 'artikelen',
    name: TICKET_ROUTES.ROUTE_ARTICLES,
    component: ArticleComponent,
    meta: {
      name: 'header.articles',
    },
  },
  {
    path: 'gegevens',
    name: TICKET_ROUTES.ROUTE_NAW,
    component: NAWComponent,
    meta: {
      name: 'header.personalDetails',
    },
  },
  {
    path: 'betalen',
    name: TICKET_ROUTES.ROUTE_PAYMENT,
    component: PaymentComponent,
    meta: {
      name: 'header.payment',
    },
  },
];

export const FLOW_NOCTURNES = [
  {
    path: '/',
    name: NOCTURNES_ROUTES.ROUTE_TICKETS,
    component: TicketComponent,
    meta: {
      name: 'header.selectItems',
    },
  },
  {
    path: 'datum-tijd',
    name: NOCTURNES_ROUTES.ROUTE_DATE,
    component: DateComponent,
    meta: {
      name: 'header.dateTime',
    },
  },
  {
    path: 'artikelen',
    name: NOCTURNES_ROUTES.ROUTE_ARTICLES,
    component: ArticleComponent,
    meta: {
      name: 'header.articles',
    },
  },
  {
    path: 'gegevens',
    name: NOCTURNES_ROUTES.ROUTE_NAW,
    component: NAWComponent,
    meta: {
      name: 'header.personalDetails',
    },
  },
  {
    path: 'betalen',
    name: NOCTURNES_ROUTES.ROUTE_PAYMENT,
    component: PaymentComponent,
    meta: {
      name: 'header.payment',
    },
  },
];

export const FLOW_BUSINESS = [
  {
    path: '/',
    name: BUSINESS_ROUTES.ROUTE_LOGIN,
    component: LoginComponent,
    meta: {
      name: 'header.login',
    },
  },
  {
    path: 'tickets',
    name: BUSINESS_ROUTES.ROUTE_TICKETS,
    component: TicketComponent,
    meta: {
      name: 'header.selectTickets',
    },
  },
  {
    path: 'datum-tijd',
    name: BUSINESS_ROUTES.ROUTE_DATE,
    component: DateComponent,
    meta: {
      name: 'header.dateTime',
    },
  },
  {
    path: 'artikelen',
    name: BUSINESS_ROUTES.ROUTE_ARTICLES,
    component: ArticleComponent,
    meta: {
      name: 'header.articles',
    },
  },
  {
    path: 'afronden',
    name: BUSINESS_ROUTES.ROUTE_COMPLETE,
    component: CompleteComponent,
    meta: {
      name: 'header.round',
    },
  },
];

export const FLOW_ADMIN = [
  {
    path: '/',
    name: ADMIN_ROUTES.ROUTE_ADMIN,
    component: AdminComponent,
    meta: {
      name: 'header.selectItems',
    }
  }
];

export const FLOW_WORKSHOP = [
  {
    path: '/',
    name: WORKSHOP_ROUTES.ROUTE_TICKETS,
    component: TicketComponent,
    meta: {
      name: 'header.selectItems',
    },
  },
  {
    path: 'datum-tijd',
    name: WORKSHOP_ROUTES.ROUTE_DATE,
    component: DateComponent,
    meta: {
      name: 'header.dateTime',
    },
  },
  {
    path: 'artikelen',
    name: WORKSHOP_ROUTES.ROUTE_ARTICLES,
    component: ArticleComponent,
    meta: {
      name: 'header.articles',
    },
  },
  {
    path: 'gegevens',
    name: WORKSHOP_ROUTES.ROUTE_NAW,
    component: NAWComponent,
    meta: {
      name: 'header.personalDetails',
    },
  },
  {
    path: 'betalen',
    name: WORKSHOP_ROUTES.ROUTE_PAYMENT,
    component: PaymentComponent,
    meta: {
      name: 'header.payment',
    },
  },
];

const ROUTER = new Router({
  routes: [
    {
      path: '/',
      redirect: '/nl',
      component: SelectComponent,
    },
    {
      path: '/nl',
      component: SelectComponent,
    },
    {
      path: '/de',
      component: SelectComponent,
    },
    {
      path: '/en',
      component: SelectComponent,
    },
    {
      path: '/fr',
      component: SelectComponent,
    },
    {
      path: '/tickets',
      component: TicketsView,
      children: FLOW_NORMAL,
    },
    {
      path: '/nocturnes',
      component: NocturnesView,
      children: FLOW_NOCTURNES,
    },
    {
      path: '/cadeaubonnen',
      component: GiftcardView,
      children: FLOW_SHARED,
    },
    {
      path: '/business',
      component: BusinessesView,
      children: FLOW_BUSINESS,
    },
    {
      path: '/admin',
      component: AdminView,
      children: FLOW_ADMIN,
    },
    {
      path: '/workshops',
      component: WorkshopView,
      children: FLOW_WORKSHOP,
    }
  ],
});

export default ROUTER;
