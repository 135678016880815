import ITranslation from '@/interfaces/ITranslation';

const dutch: ITranslation = {
  marketingQuestion: {
    default: 'Kies een bron/medium',
    question: 'Hoe bent u bij ons gekomen?',
    answers: [
      {
        value: 'facebook',
        name: 'Facebook'
      },
      {
        value: 'instagram',
        name: 'Instagram'
      },
      {
        value: 'tiktok',
        name: 'TikTok'
      },
      {
        value: 'google',
        name: 'Google'
      },
      {
        value: 'tripadvisor',
        name: 'Tripadvisor'
      },
      {
        value: 'via-via',
        name: 'Via via'
      },
      {
        value: 'hotel',
        name: 'Aanbevolen door het hotel'
      },
      {
        value: 'tijdschrift-krant-tv',
        name: 'Tijdschrift/Krant/Televisie'
      },
      {
        value: 'toeristische-dienst',
        name: 'Toeristische dienst'
      }
    ]
  },
  buttons: {
    next: 'DOORGAAN',
    previous: 'TERUG'
  },
  dateTimePage: {
    legend: {
      availableDate: 'Beschikbaar',
      chosenDate: 'Gekozen datum',
      notAvailableDate: 'Niet beschikbaar',
      title: 'Legenda'
    },
    title: 'Kies een bezoekdatum',
    workShop: {
      placesAvailable: 'Nog {0} plaatsen beschikbaar',
      placeAvailable: 'Nog {0} plaats beschikbaar'
    },
    times: {
      title: 'Selecteer jouw starttijd',
      choose: 'Kies'
    }
  },
  articlePage: {
    title: 'Selecteer je artikelen',
    paragraph: '<b>Optioneel: <br/> Kies uit de volgende huisgemaakte chocolade-heerlijkheden aan voordeeltarief.</b>',
    next: 'Doorgaan zonder voordeel'
  },
  errors: {
    noTimesAvailable: 'Er zijn geen tijdsloten gevonden',
    createOrder: 'Er is een fout opgetreden tijdens het aanmaken van de order',
    loginDenied: 'Vul alle gegevens in',
    repeatPassword: 'De gekozen wachtwoorden komen niet overeen',
    updateData: 'Er ging iets fout tijdens het bijwerken van je gegevens',
    emailNotAdded: 'Er ging iets fout tijdens het toevoegen van dit emailadres',
    emailNotRemoved: 'Er ging iets fout tijdens het verwijderen van dit emailadres',
    gettingUsers: 'Er ging iets fout tijdens het ophalen van alle gebruikers',
    whoops: 'Oeps!',
    naw: {
      error: 'Je bent een aantal velden vergeten in te vullen. Controleer alle velden opnieuw voordat u verder gaat.',
      accept: 'Accepteer de algemene voorwaarde aub!',
      answer: 'Beantwoord bovenstaande vraag aub!',
      email: 'Vul een geldig email adres in!',
      firstName: 'Vul een voornaam in!',
      lastName: 'Vul een achternaam in!',
      acceptCorona: 'Accepteer onze hygiënemaatregelen aub!',
      country: 'Vul uw land van herkomst in aub!'
    }
  },
  footer: {
    copyright: 'The Belgian Chocolate Experience'
  },
  header: {
    dateTime: 'Datum/Tijd',
    payment: 'Betaling',
    personalDetails: 'Jouw gegevens',
    selectItems: 'Selecteer items',
    round: 'Afronden',
    selectTickets: 'Selecteer tickets',
    login: 'Login',
    articles: 'Chocolade-voordeel'
  },
  loaders: {
    collectingAvailableTimes: 'Beschikbare tijden worden opgehaald',
    creatingPayment: 'Binnen enkele seconden zul je worden doorverwezen naar de betaalpagina.',
    collectingTickets: 'Bezig met ophalen van tickets',
    checkingPersonalDetails: 'Je gegevens worden gecontroleerd.',
    loadingHeader: 'Even geduld aub...',
    loadingAdmin: 'De juiste gegevens worden geladen',
    finishingOrder: 'Je bestelling wordt afgerond'
  },
  normalTicketPage: {
    minimumTicketSelection: 'Kies minimaal 1 ticket om door te gaan. Voor kinderen onder de 4 jaar is er geen (tijdslot-)reservering nodig. Die gaan samen met hun begeleiders mee naar binnen.',
    title: 'Selecteer je tickets'
  },
  paymentPage: {
    paymentOverview: {
      button: 'NAAR BETALEN',
      notice: 'Indien de betaling succesvol is, zul je binnen enkele minuten je e-tickets ontvangen op opgegeven e-mailadres.',
      title: 'Besteloverzicht'
    },
    title: 'Bestelling afronden'
  },
  personalDetailsPage: {
    marketing: 'Ja, ik ben een chocoladefan en ontvang graag het laatste chocoladenieuws.',
    coronaCheckBox: 'Bij reserveren wordt bezoekers gevraagd akkoord te gaan met de hygiënemaatregelen en de maatregelen bij gezondheidsklachten als aanvulling op de bezoekvoorwaarden van het museum.*',
    checkBox: 'Door deze bestelling te plaatsen ga ik akkoord met de <a href=\'https://www.chocolatenation.be/nl/boekingsvoorwaarden-tickets\' target=\'_blank\'>algemene voorwaarden</a>*',
    email: 'E-mailadres',
    emailRepeat: 'E-mailadres herhaling',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    middleName: 'Tussenvoegsel',
    notice: {
      description: 'Er zullen e-tickets naar het e-mailadres worden verstuurd welke hier opgegeven wordt. Controleer dus dubbel of je deze goed invult.',
      title: 'Belangrijke mededeling!',
    },
    phoneNumber: 'Telefoonnummer',
    birthDay: {
      labelActive: 'Vul je geboortedatum in om iets leuks te ontvangen op je verjaardag',
      labelInactive: 'Geboortedatum - Abonneer je op onze nieuwsbrief om iets leuks te ontvangen op je verjaardag',
      notice: 'Ja, ik ben een chocoladeliefhebber en wil graag het laatste nieuws over chocolade ontvangen'
    },
    title: 'Persoonlijke gegevens',
    country: 'Land',
  },
  selectionPage: {
    nocturnesTicket: {
      description: 'Met deze tickets krijg je toegang tot Chocolate Nation inclusief paasbrunch  (excl. alcoholische dranken) in restaurant Octave.',
      title: 'Ticket Pasen'
    },
    next: 'VOLGENDE',
    giftCardTicket: {
      description: 'Hier kan je cadeaubonnen bestellen.',
      title: 'Cadeaubonnen'
    },
    normalTicket: {
      description: 'Met deze tickets krijg je toegang tot Chocolate Nation.',
      title: 'Tickets'
    },
    title: 'Kies welke soort tickets je wil bestellen:',
    workshopTicket: {
      description: 'Met deze tickets kan je een workshop volgen en toegang krijgen tot Chocolate Nation',
      title: 'Tickets inclusief workshop',
      notice: ''
    }
  },
  shoppingCart: {
    subtitleTicket: 'ticket(s)',
    subtitleTotal: 'Totaal',
    emptySubtitle: 'Nog geen tickets gekozen',
    emptyMessage: 'Momenteel heb je nog geen tickets in je winkelwagen. Voel je vrij om tickets te selecteren!',
    emptyMessageTitle: 'Winkelwagen is leeg!',
    title: 'Mijn winkelwagen',
    maximumAmountReached: 'Je kunt niet meer dan {0} kiezen voor dit onderdeel.',
    notice: {
      title: 'Toegevoegd!',
      description: 'Klik op het wagentje om deze te openen.'
    }
  },
  ticket: {
    addButton: 'TOEVOEGEN'
  },
  voucher: {
    button: 'TOEPASSEN',
    placeholder: 'Voucher code'
  },
  nocturnesTicketPage: {
    title: 'Selecteer je ticket (1 ticket per persoon)',
    minimumTicketSelection: "<b>Kies minimaal 1 ticket om door te gaan.</b><br />Toegang museum + paasbrunch in restaurant Octave<br />Ontvangst vanaf 11u - brunch 11u30 – start museumbezoek vanaf 14u"
  },
  workShopTicketPage: {
    title: 'Selecteer je workshop',
    minimumTicketSelection: 'Kies minimaal 1 ticket om door te gaan.',
    voucherNotValid: 'Deze voucher code is niet geldig in combinatie met een workshop',
    voucherOnlyValid: 'Deze voucher code is alleen geldig in combinatie met workshop tickets',
    invalidMessage: 'Voucher niet geldig',
    voucherErrorMessage: 'Kon de voucher niet ophalen probeer het later opnieuw.'
  },
  giftCardTicketPage: {
    title: 'Selecteer je cadeaubonnen',
    minimumTicketSelection: 'Kies minimaal 1 ticket om door te gaan. Voor kinderen onder de 4 jaar is er geen (tijdslot-)reservering nodig. Die gaan samen met hun begeleiders mee naar binnen.'
  },
  adminPage: {
    title: 'Administratie paneel',
    addEmail: {
      title: 'Email toevoegen',
      button: 'Toevoegen',
      newEmail: 'Nieuw emailadres',
      successMessage: '{0} is toegevoegd en zal automatisch een mail krijgen met de inloggegevens.',
      removeMessage: '{0} is verwijderd'
    },
    allEmails: {
      title: 'Alle emails',
      button: 'Verwijderen'
    }
  },
  completePage: {
    title: 'Bestelling Afronden',
    order: {
      title: 'Bestelling',
      ticket: 'Ticket',
      amount: 'Aantal',
      price: 'Totaal prijs'
    },
    totalPrice: 'Totaal:',
    dateTime: 'Datum:',
    checkBox: 'Ik ga ermee akkoord dat ik hiermee een order plaats. Orders worden aan het<br> eind van elke maand verzameld en in een keer gefactureerd.',
    button: 'Bestelling plaatsen',
    finishOrder: {
      title: 'Bestelling afronden',
      description: 'Uw bestelling is geplaatst en afgerond. De e-tickets ontvangt u binnen 10 minuten in uw mailbox.',
      button: 'Nieuwe bestelling plaatsen'
    }
  },
  loginPage: {
    title: 'Inloggen',
    email: 'Email:',
    password: 'Wachtwoord:',
    button: 'Inloggen',
    companyDetails: {
      title: 'Bedrijfsgegevens',
      contactPerson: '* Contactpersoon',
      companyName: '* Bedrijfsnaam',
      address: '* Adres',
      zipCode: '* Postcode',
      city: '* Plaats',
      vatNumber: 'BTW nummer',
      password: {
        title: 'Wachtwoord',
        password: '* Nieuw wachtwoord',
        repeat: '* Herhaal nieuw wachtwoord'
      },
      checkBox: 'Ik ga akkoord met de <a href="http://www.thebelgianchocolateexperience.be/algemenevoorwaarden" target="_blank">algemene voorwaarden</a>',
      notice: {
        title: 'Eerste login',
        description: 'Welkom in het Chocolate nation business portaal!<br><br> Ons systeem heeft vernomen dat dit de eerste keer is dat u inlogt. U moet daarom eenmalig uw bedrijfsgegevens invullen en uw wachtwoord aanpassen.<br><br> Bedankt voor uw begrip.',
        button: 'Opslaan'
      }
    }
  },
  extraQuestions: {
    group_type: 'Om wat voor soort groep gaat het?',
    primary_school: 'Basisschool',
    secondary_school: 'Middelbare school',
    family_group: 'Familie groep',
    students_group: 'Groep studenten',
    friends_group: 'Vrienden groep',
    bachelor_group: 'Bachelor(ette) groep',
    retirees_group: 'Groep gepensioneerden',
    disabilities_group: 'Groep mensen met een handicap',
    other: 'Overig',
    disabilities: 'Zijn er mensen die hulp nodig hebben of slecht ter been zijn?',
    yes: 'Ja',
    no: 'Nee',
    disabilities_amount: 'Om hoeveel mensen gaat het?',
    warning: 'NB! De groep dient op het geboekte tijdstip aanwezig te zijn. Niet te vroeg of te laat. Als dit gebeurt, kunnen we uw toegang helaas niet garanderen.'
  },
  countries: {
    choose: 'Kies een land',
    af: 'Afghanistan',
    ax: 'Aland-eilanden',
    al: 'Albanië',
    dz: 'Algerije',
    as: 'Amerikaans Samoa',
    ad: 'Andorra',
    ao: 'Angola',
    ai: 'Anguilla',
    aq: 'Antarctica',
    ag: 'Antigua en Barbuda',
    ar: 'Argentinië',
    am: 'Armenië',
    aw: 'Aruba',
    au: 'Australië',
    at: 'Oostenrijk',
    az: 'Azerbeidzjan',
    bs: 'Bahamas',
    bh: 'Bahrein',
    bd: 'Bangladesh',
    bb: 'Barbados',
    by: 'Wit-Rusland',
    be: 'Belgie',
    bz: 'Belize',
    bj: 'Benin',
    bm: 'Bermuda',
    bt: 'Bhutan',
    bo: 'Bolivia',
    bq: 'Bonaire, Sint Eustatius en Saba',
    ba: 'Bosnië-Herzegovina',
    bw: 'Botswana',
    bv: 'Bouvet-eiland',
    br: 'Brazilië',
    io: 'Brits-Indisch oceaan gebied',
    bn: 'Brunei Darussalam',
    bg: 'Bulgarije',
    bf: 'Burkina Faso',
    bi: 'Burundi',
    kh: 'Cambodja',
    cm: 'Kameroen',
    ca: 'Canada',
    cv: 'Kaapverdië',
    ky: 'Kaaiman Eilanden',
    cf: 'Centraal Afrikaanse Republiek',
    td: 'Tsjaad',
    cl: 'Chili',
    cn: 'China',
    cx: 'Kersteiland',
    cc: 'Cocos (Keeling) eilanden',
    co: 'Colombia',
    km: 'Comoren',
    cg: 'Congo',
    cd: 'Congo, Democratische Republiek Congo',
    ck: 'Cook Eilanden',
    cr: 'Costa Rica',
    ci: 'Ivoorkust',
    hr: 'Kroatië',
    cu: 'Cuba',
    cw: 'Curacao',
    cy: 'Cyprus',
    cz: 'Tsjechië',
    dk: 'Denemarken',
    dj: 'Djibouti',
    dm: 'Dominica',
    do: 'Dominicaanse Republiek',
    ec: 'Ecuador',
    eg: 'Egypte',
    sv: 'El Salvador',
    gq: 'Equatoriaal-Guinea',
    er: 'Eritrea',
    ee: 'Estland',
    et: 'Ethiopië',
    fk: 'Falklandeilanden (Malvinas)',
    fo: 'Faeröer',
    fj: 'Fiji',
    fi: 'Finland',
    fr: 'Frankrijk',
    gf: 'Frans Guyana',
    pf: 'Frans-Polynesië',
    tf: 'Franse zuidelijke gebieden',
    ga: 'Gabon',
    gm: 'Gambia',
    ge: 'Georgië',
    de: 'Duitsland',
    gh: 'Ghana',
    gi: 'Gibraltar',
    gr: 'Griekenland',
    gl: 'Groenland',
    gd: 'Grenada',
    gp: 'Guadeloupe',
    gu: 'Guam',
    gt: 'Guatemala',
    gg: 'Guernsey',
    gn: 'Guinea',
    gw: 'Guinee-Bissau',
    gy: 'Guyana',
    ht: 'Haïti',
    hm: 'Heard Island en Mcdonald Islands',
    va: 'Vaticaanstad',
    hn: 'Honduras',
    hk: 'Hong Kong',
    hu: 'Hongarije',
    is: 'IJsland',
    in: 'India',
    id: 'Indonesië',
    ir: 'Iran, Islamitische Republiek',
    iq: 'Irak',
    ie: 'Ierland',
    im: 'Isle of Man',
    il: 'Israël',
    it: 'Italië',
    jm: 'Jamaica',
    jp: 'Japan',
    je: 'Jersey',
    jo: 'Jordanië',
    kz: 'Kazachstan',
    ke: 'Kenia',
    ki: 'Kiribati',
    kp: 'Korea, Democratische Volksrepubliek',
    kr: 'Korea, republiek van',
    xk: 'Kosovo',
    kw: 'Koeweit',
    kg: 'Kirgizië',
    la: 'Lao Democratische Volksrepubliek',
    lv: 'Letland',
    lb: 'Libanon',
    ls: 'Lesotho',
    lr: 'Liberia',
    ly: 'Libië',
    li: 'Liechtenstein',
    lt: 'Litouwen',
    lu: 'Luxemburg',
    mo: 'Macao',
    mk: 'Macedonië, de voormalige Joegoslavische Republiek',
    mg: 'Madagascar',
    mw: 'Malawi',
    my: 'Maleisië',
    mv: 'Malediven',
    ml: 'Mali',
    mt: 'Malta',
    mh: 'Marshall eilanden',
    mq: 'Martinique',
    mr: 'Mauritanië',
    mu: 'Mauritius',
    yt: 'Mayotte',
    mx: 'Mexico',
    fm: 'Micronesië, Federale Staten van',
    md: 'Moldavië, Republiek',
    mc: 'Monaco',
    mn: 'Mongolië',
    me: 'Montenegro',
    ms: 'Montserrat',
    ma: 'Marokko',
    mz: 'Mozambique',
    mm: 'Myanmar',
    na: 'Namibië',
    nr: 'Nauru',
    np: 'Nepal',
    nl: 'Nederland',
    an: 'Nederlandse Antillen',
    nc: 'Nieuw-Caledonië',
    nz: 'Nieuw-Zeeland',
    ni: 'Nicaragua',
    ne: 'Niger',
    ng: 'Nigeria',
    nu: 'Niue',
    nf: 'Norfolkeiland',
    mp: 'noordelijke Mariana eilanden',
    no: 'Noorwegen',
    om: 'Oman',
    pk: 'Pakistan',
    pw: 'Palau',
    ps: 'Palestijns gebied, bezet',
    pa: 'Panama',
    pg: 'Papoea-Nieuw-Guinea',
    py: 'Paraguay',
    pe: 'Peru',
    ph: 'Filippijnen',
    pn: 'Pitcairn',
    pl: 'Polen',
    pt: 'Portugal',
    pr: 'Puerto Rico',
    qa: 'Qatar',
    re: 'Réunion',
    ro: 'Roemenië',
    ru: 'Russische Federatie',
    rw: 'Rwanda',
    bl: 'Sint-Bartholomeus',
    sh: 'Sint-Helena',
    kn: 'Saint Kitts en Nevis',
    lc: 'Saint Lucia',
    mf: 'Sint-Maarten',
    pm: 'Saint Pierre en Miquelon',
    vc: 'Saint Vincent en de Grenadines',
    ws: 'Samoa',
    sm: 'San Marino',
    st: 'Sao Tomé en Principe',
    sa: 'Saoedi-Arabië',
    sn: 'Senegal',
    rs: 'Servië',
    cs: 'Servië en Montenegro',
    sc: 'Seychellen',
    sl: 'Sierra Leone',
    sg: 'Singapore',
    sx: 'Sint Maarten',
    sk: 'Slowakije',
    si: 'Slovenië',
    sb: 'Solomon eilanden',
    so: 'Somalië',
    za: 'Zuid-Afrika',
    gs: 'Zuid-Georgië en de Zuidelijke Sandwicheilanden',
    ss: 'Zuid Soedan',
    es: 'Spanje',
    lk: 'Sri Lanka',
    sd: 'Soedan',
    sr: 'Suriname',
    sj: 'Svalbard en Jan Mayen',
    sz: 'Swaziland',
    se: 'Zweden',
    ch: 'Zwitserland',
    sy: 'Syrische Arabische Republiek',
    tw: 'Taiwan, provincie China',
    tj: 'Tadzjikistan',
    tz: 'Tanzania, Verenigde Republiek',
    th: 'Thailand',
    tl: 'Oost-Timor',
    tg: 'Togo',
    tk: 'Tokelau',
    to: 'Tonga',
    tt: 'Trinidad en Tobago',
    tn: 'Tunesië',
    tr: 'Turkije',
    tm: 'Turkmenistan',
    tc: 'Turks- en Caicoseilanden',
    tv: 'Tuvalu',
    ug: 'Oeganda',
    ua: 'Oekraïne',
    ae: 'Verenigde Arabische Emiraten',
    gb: 'Verenigd Koningkrijk',
    us: 'Verenigde Staten',
    um: 'Kleine afgelegen eilanden van de Verenigde Staten',
    uy: 'Uruguay',
    uz: 'Oezbekistan',
    vu: 'Vanuatu',
    ve: 'Venezuela',
    vn: 'Vietnam',
    vg: 'Britse Maagdeneilanden',
    vi: 'Maagdeneilanden, U.s.',
    wf: 'Wallis en Futuna',
    eh: 'Westelijke Sahara',
    ye: 'Jemen',
    zm: 'Zambia',
    zw: 'Zimbabwe',
  }
};

export default dutch;

