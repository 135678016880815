import ITranslation from '@/interfaces/ITranslation';

const french: ITranslation = {
  marketingQuestion: {
    default: 'Choisissez une source/un support',
    question: 'Comment avez-vous entendu parler de nous?',
    answers: [
      {
        value: 'facebook',
        name: 'Facebook'
      },
      {
        value: 'instagram',
        name: 'Instagram'
      },
      {
        value: 'tiktok',
        name: 'TikTok'
      },
      {
        value: 'google',
        name: 'Google'
      },
      {
        value: 'tripadvisor',
        name: 'Tripadvisor'
      },
      {
        value: 'via-via',
        name: 'Bouche à oreille'
      },
      {
        value: 'hotel',
        name: 'Recommandé par l\'hôtel'
      },
      {
        value: 'tijdschrift-krant-tv',
        name: 'Magazine/Journal/Télévision'
      },
      {
        value: 'toeristische-dienst',
        name: 'Office de tourisme'
      }
    ]
  },
  buttons: {
    next: 'Continuer',
    previous: 'Retourner'
  },
  dateTimePage: {
    legend: {
      availableDate: 'Disponible',
      chosenDate: 'Date sélectionnée',
      notAvailableDate: 'Non disponible',
      title: 'Légende'
    },
    title: 'Choisissez une date ',
    workShop: {
      placesAvailable: '{0} places disponibles',
      placeAvailable: '{0} place disponibles',
    },
    times: {
      title: 'Sélectionnez votre créneau horaire',
      choose: 'Choisir'
    }
  },
  articlePage: {
    title: 'Sélectionnez vos articles',
    paragraph: '<b>Facultatif: <br/> Faites votre choix parmi les spécialités de chocolat maison suivantes à un tarif spécial.</b>',
    next: 'Continuer sans bénéfice'
  },
  errors: {
    noTimesAvailable: 'Aucune horaire disponible n’a été trouvée',
    createOrder: 'Une erreur s\'est produite lors de la création de la commande',
    loginDenied: 'Remplissez toutes les données',
    repeatPassword: 'Les mots de passe introduits ne correspondent pas',
    updateData: 'Une erreur s’est produite lors de la modification de vos données.',
    emailNotAdded: 'Une erreur s’est produite quand vous avez supprimé cette adresse e-mail.',
    emailNotRemoved: 'Une erreur s’est produite quand vous avez supprimé cette adresse e-mail.',
    gettingUsers: 'Une erreur s’est produite lors du chargement de tout les utilisateurs.',
    whoops: 'Oups!',
    naw: {
      error: 'Vous avez oublié de remplir certains champs. Veuillez vérifier à nouveau tous les champs avant de continuer.',
      accept: 'Veuillez accepter les conditions générales !',
      answer: 'Veuillez répondre à la question ci-dessus s\'il vous plaît !',
      email: 'Veuillez saisir une adresse e-mail valide !',
      firstName: 'Veuillez saisir un prénom !',
      lastName: 'Veuillez saisir un nom de famille !',
      acceptCorona: 'Veuillez accepter nos mesures d\'hygiène !',
      country: 'Veuillez saisir votre pays d\'origine s\'il vous plaît !'
    }
  },
  footer: {
    copyright: 'The Belgian Chocolate Experience'
  },
  header: {
    dateTime: 'Date/heure',
    payment: 'Paiement',
    personalDetails: 'Vos détails',
    selectItems: 'Sélectionnez des articles',
    round: 'Compléter ',
    selectTickets: 'Sélectionnez des billets',
    login: 'Connexion',
    articles: 'Bénéfice du chocolat'
  },
  loaders: {
    collectingAvailableTimes: 'Les horaires disponibles sont affichées',
    creatingPayment: 'Dans quelques secondes, vous serez redirigé vers la page de paiement.',
    collectingTickets: 'Obtenir vos billets',
    checkingPersonalDetails: 'Vos données seront contrôlées.',
    loadingHeader: 'Un instant s’il vous plait...',
    loadingAdmin: 'Les données correctes sont en cours de chargement',
    finishingOrder: 'Votre commande sera complétée'
  },
  normalTicketPage: {
    minimumTicketSelection: 'Choisissez au minimum 1 ticket pour continuer. Pour les enfants de moins de 4 ans aucune réservation (plage horaire) n’est requise. Ils entrent avec leurs accompagnateurs.',
    title: 'Sélectionnez vos billets'
  },
  paymentPage: {
    paymentOverview: {
      button: 'Continuez vers paiement',
      notice: 'Si le paiement est réussi, vous recevrez vos billets électroniques à l\'adresse électronique spécifiée dans quelques minutes.',
      title: 'Sommaire de votre commande'
    },
    title: 'Commande complète'
  },
  personalDetailsPage: {
    marketing: 'Oui, je suis un grand fan de chocolat et je souhaite recevoir les dernières nouvelles sur le chocolat.',
    coronaCheckBox: 'Lors de la réservation, les visiteurs sont priés d\'accepter les mesures d\'hygiène et les mesures pour les plaintes de santé en plus des conditions de visite du musée.*',
    checkBox: 'Confirmant cette commande, vous acceptez <a href=\'https://www.chocolatenation.be/fr/conditions-de-reservation-billets\' target=\'_blank\'>les conditions générales</a>*',
    email: 'Adresse e-mail',
    emailRepeat: 'Répétition d\'adresse e-mail',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    middleName: 'Insertion',
    notice: {
      description: 'Les billets électroniq ues seront envoyés à l’adresse e-mail indiquée ici. Vérifiez svp que l’adresse est correcte.',
      title: 'Communication importante!',
    },
    phoneNumber: 'Numéro de téléphone',
    birthDay: {
      labelActive: 'Indiquez votre date de naissance pour recevoir queue chose d\'amusant le jour de votre anniversaire',
      labelInactive: 'Date de naissance - Abonnez-vous à notre newsletter pour recevoir quelque chose d\'amusant le jour de votre anniversaire',
      notice: 'Oui, je suis un fan de chocolat et j\'aimerais recevoir les dernières nouvelles sur le chocolat'
    },
    title: 'Vos données personnels',
    country: 'Pays'
  },
  selectionPage: {
    nocturnesTicket: {
      description: 'Ces billets vous donnent accès à la Chocolate Nation, y compris le brunch de Pâques (hors boissons alcoolisées) au restaurant Octave.',
      title: 'Billet de Pâques'
    },
    next: 'CONTINUER',
    giftCardTicket: {
      description: 'Ici, vous pouvez acheter des chèques cadeaux.',
      title: 'Chèques cadeaux'
    },
    normalTicket: {
      description: 'Avec ces billets, vous avez accès à Chocolate Nation.',
      title: 'Billets'
    },
    title: 'Choisissez le type de billets que vous souhaitez commander:',
    workshopTicket: {
      description: 'Ces billets vous permettent de participer à un atelier dédié au chocolat et d\'accéder à Chocolate',
      title: 'Billets incluant l\'atelier',
      notice: '(Attention : les ateliers se dérouleront uniquement en néerlandais et en anglais.)'
    }
  },
  shoppingCart: {
    subtitleTicket: 'billet(s)',
    subtitleTotal: 'Total',
    emptySubtitle: 'Aucun billet sélectionné',
    emptyMessage: 'Actuellement vous n’avez pas de billets dans votre panier. N’hésitez pas à choisir des billets!',
    emptyMessageTitle: 'Votre panier est vide!',
    title: 'Mon panier',
    maximumAmountReached: 'Vous ne pouvez pas choisir plus de {0} pour cette partie.',
    notice: {
      title: 'Ajouté!',
      description: 'Cliquez sur le panier pour l\'ouvrir.'
    }
  },
  ticket: {
    addButton: 'AJOUTER'
  },
  voucher: {
    button: 'Appliquer',
    placeholder: 'Code de voucher'
  },
  workShopTicketPage: {
    title: 'Sélectionnez votre atelier',
    minimumTicketSelection: 'Choisissez au minimum 1 ticket pour continuer.',
    voucherNotValid: 'Ce code de bon d\'achat n\'est pas valide en combinaison avec un atelier',
    voucherOnlyValid: 'Ce code de bon d\'achat est uniquement valide en combinaison avec des billets d\'atelier',
    invalidMessage: 'Le bon n\'est pas valide',
    voucherErrorMessage: 'Impossible de récupérer le voucher, veuillez réessayer ultérieurement.'
  },
  nocturnesTicketPage: {
    title: 'Sélectionnez votre billet (1 billet par personne)',
    minimumTicketSelection: '<b>Sélectionnez au moins 1 billet pour continuer.</b><br />Entrée au musée + brunch de Pâques au restaurant Octave<br />Accueil à partir de 11h - brunch à 11h30 – début de la visite du musée à partir de 14h'
  },
  giftCardTicketPage: {
    title: 'Sélectionnez vos bons cadeaux',
    minimumTicketSelection: 'Choisissez au minimum 1 ticket pour continuer. Pour les enfants de moins de 4 ans aucune réservation (plage horaire) n’est requise. Ils entrent avec leurs accompagnateurs.'
  },
  adminPage: {
    title: 'Panneau d’administration',
    addEmail: {
      title: 'Ajouter un mail',
      button: 'Ajouter',
      newEmail: 'Nouvelle adresse e-mail',
      successMessage: '{0} a été ajouté et recevra automatiquement un e-mail avec les informations de connexion.',
      removeMessage: '{0} a été supprimé'
    },
    allEmails: {
      title: 'Tous les mails',
      button: 'Supprimer'
    }
  },
  completePage: {
    title: 'Compléter la commande',
    order: {
      title: 'Commande',
      ticket: 'Billet',
      amount: 'Nombre',
      price: 'Prix total'
    },
    totalPrice: 'Total:',
    dateTime: 'Date:',
    checkBox: 'Je suis d\'accord que je place un ordre ci-joint. Les commandes sont<br> rassemblées à la fin de chaque mois et facturées à en une fois.',
    button: 'Passer la commande',
    finishOrder: {
      title: 'Commande complète',
      description: 'Votre commande est placée et terminée. Vous recevrez les billets électroniques dans 10 minutes dans votre boîte de réception.',
      button: 'Commencer une nouvelle commande'
    }
  },
  loginPage: {
    title: 'Connexion',
    email: 'Email:',
    password: 'Mot de passe:',
    button: 'Connexion',
    companyDetails: {
      title: 'L’information d’entreprise',
      contactPerson: '* Personne de contact',
      companyName: '* Nom de l’entreprise',
      address: '* Adresse',
      zipCode: '* Code postal',
      city: '* Place',
      vatNumber: 'Numéro de TVA',
      password: {
        title: 'Mot de passe ',
        password: '* Nouveau mot de passe',
        repeat: '* Répétez nouveau mot de passe'
      },
      checkBox: 'Je suis d’accord avec les <a href="http://www.thebelgianchocolateexperience.be/algemenevoorwaarden" target="_blank">termes et conditions</a>',
      notice: {
        title: 'Première connexion',
        description: 'Bienvenue dans le portail professionnel de Chocolate Nation!<br><br> Le système nous dit que c’est la première fois que vous vous connectez. Vous devez remplir les données d’entreprise et changer votre mot de passe.<br><br> Merci de votre compréhension.',
        button: 'Sauvegarder'
      }
    }
  },
  extraQuestions: {
    group_type: 'De quel type de groupe s’agit-il ?',
    primary_school: 'Ecole primaire',
    secondary_school: 'Ecole secondaire',
    family_group: 'Groupe de famille',
    students_group: 'Groupe d’étudiants',
    friends_group: 'Groupe d’ami(e)s',
    bachelor_group: 'Groupe de célibataires',
    retirees_group: 'Groupe de retraité(e)s',
    disabilities_group: 'Groupe de personnes handicapées',
    other: 'Autre',
    disabilities: 'Y a-t-il des personnes qui ont besoin d’assistance ou qui ont des difficultés à marcher ?',
    yes: 'Oui',
    no: 'Non',
    disabilities_amount: 'Combien de personnes y a-t-il ?',
    warning: 'NB ! Le groupe doit arriver à l\'heure réservée. Ni trop tôt ni trop tard. Si cela se produit, nous ne pouvons malheureusement pas garantir votre accès.'
  },
  countries: {
    choose: 'Choisissez un pays',
    af: 'L\'Afghanistan',
    ax: 'Iles Aland',
    al: 'Albanie',
    dz: 'Algérie',
    as: 'Samoa américaines',
    ad: 'Andorre',
    ao: 'L\'Angola',
    ai: 'Anguilla',
    aq: 'Antarctique',
    ag: 'Antigua-et-Barbuda',
    ar: 'Argentine',
    am: 'Arménie',
    aw: 'Aruba',
    au: 'Australie',
    at: 'L\'Autriche',
    az: 'Azerbaïdjan',
    bs: 'Bahamas',
    bh: 'Bahreïn',
    bd: 'Bangladesh',
    bb: 'Barbade',
    by: 'Biélorussie',
    be: 'Belgique',
    bz: 'Belize',
    bj: 'Bénin',
    bm: 'Bermudes',
    bt: 'Bhoutan',
    bo: 'Bolivie',
    bq: 'Bonaire, Saint-Eustache et Saba',
    ba: 'Bosnie Herzégovine',
    bw: 'Botswana',
    bv: 'Île Bouvet',
    br: 'Brésil',
    io: 'Territoire britannique de l\'océan Indien',
    bn: 'Brunei Darussalam',
    bg: 'Bulgarie',
    bf: 'Burkina Faso',
    bi: 'Burundi',
    kh: 'Cambodge',
    cm: 'Cameroun',
    ca: 'Canada',
    cv: 'Cap-Vert',
    ky: 'Îles Caïmans',
    cf: 'République centrafricaine',
    td: 'Tchad',
    cl: 'Chili',
    cn: 'Chine',
    cx: 'L\'île de noël',
    cc: 'Îles Cocos (Keeling)',
    co: 'Colombie',
    km: 'Comores',
    cg: 'Congo',
    cd: 'Congo, République démocratique du Congo',
    ck: 'les Îles Cook',
    cr: 'Costa Rica',
    ci: 'Côte d\'Ivoire',
    hr: 'Croatie',
    cu: 'Cuba',
    cw: 'Curacao',
    cy: 'Chypre',
    cz: 'République Tchèque',
    dk: 'Danemark',
    dj: 'Djibouti',
    dm: 'Dominique',
    do: 'République dominicaine',
    ec: 'Equateur',
    eg: 'Egypte',
    sv: 'Le Salvador',
    gq: 'Guinée Équatoriale',
    er: 'Érythrée',
    ee: 'Estonie',
    et: 'Ethiopie',
    fk: 'Îles Falkland (Malvinas)',
    fo: 'Îles Féroé',
    fj: 'Fidji',
    fi: 'Finlande',
    fr: 'France',
    gf: 'Guyane Française',
    pf: 'Polynésie française',
    tf: 'Terres australes françaises',
    ga: 'Gabon',
    gm: 'Gambie',
    ge: 'Géorgie',
    de: 'Allemagne',
    gh: 'Ghana',
    gi: 'Gibraltar',
    gr: 'Grèce',
    gl: 'Groenland',
    gd: 'Grenade',
    gp: 'Guadeloupe',
    gu: 'Guam',
    gt: 'Guatemala',
    gg: 'Guernesey',
    gn: 'Guinée',
    gw: 'Guinée-Bissau',
    gy: 'Guyane',
    ht: 'Haïti',
    hm: 'Îles Heard et McDonald',
    va: 'Saint-Siège (État de la Cité du Vatican)',
    hn: 'Honduras',
    hk: 'Hong Kong',
    hu: 'Hongrie',
    is: 'Islande',
    in: 'Inde',
    id: 'Indonésie',
    ir: 'Iran (République islamique d',
    iq: 'Irak',
    ie: 'Irlande',
    im: 'île de Man',
    il: 'Israël',
    it: 'Italie',
    jm: 'Jamaïque',
    jp: 'Japon',
    je: 'Jersey',
    jo: 'Jordan',
    kz: 'Kazakhstan',
    ke: 'Kenya',
    ki: 'Kiribati',
    kp: 'République populaire démocratique de Corée',
    kr: 'Corée, République de',
    xk: 'Kosovo',
    kw: 'Koweit',
    kg: 'Kirghizistan',
    la: 'République démocratique populaire lao',
    lv: 'Lettonie',
    lb: 'Liban',
    ls: 'Lesotho',
    lr: 'Libéria',
    ly: 'Jamahiriya arabe libyenne',
    li: 'Liechtenstein',
    lt: 'Lituanie',
    lu: 'Luxembourg',
    mo: 'Macao',
    mk: 'Macédoine, ancienne République yougoslave de',
    mg: 'Madagascar',
    mw: 'Malawi',
    my: 'Malaisie',
    mv: 'Maldives',
    ml: 'Mali',
    mt: 'Malte',
    mh: 'Iles Marshall',
    mq: 'Martinique',
    mr: 'Mauritanie',
    mu: 'Ile Maurice',
    yt: 'Mayotte',
    mx: 'Mexique',
    fm: 'Micronésie, États fédérés de',
    md: 'Moldova, République de',
    mc: 'Monaco',
    mn: 'Mongolie',
    me: 'Monténégro',
    ms: 'Montserrat',
    ma: 'Maroc',
    mz: 'Mozambique',
    mm: 'Myanmar',
    na: 'Namibie',
    nr: 'Nauru',
    np: 'Népal',
    nl: 'Pays-Bas',
    an: 'Antilles néerlandaises',
    nc: 'Nouvelle Calédonie',
    nz: 'Nouvelle-Zélande',
    ni: 'Nicaragua',
    ne: 'Niger',
    ng: 'Nigeria',
    nu: 'Niue',
    nf: 'l\'ile de Norfolk',
    mp: 'Îles Mariannes du Nord',
    no: 'Norvège',
    om: 'Oman',
    pk: 'Pakistan',
    pw: 'Palau',
    ps: 'Territoire palestinien, occupé',
    pa: 'Panama',
    pg: 'Papouasie Nouvelle Guinée',
    py: 'Paraguay',
    pe: 'Pérou',
    ph: 'Philippines',
    pn: 'Pitcairn',
    pl: 'Pologne',
    pt: 'Le Portugal',
    pr: 'Porto Rico',
    qa: 'Qatar',
    re: 'Réunion',
    ro: 'Roumanie',
    ru: 'Fédération Russe',
    rw: 'Rwanda',
    bl: 'Saint Barthélemy',
    sh: 'Sainte-Hélène',
    kn: 'Saint-Christophe-et-Niévès',
    lc: 'Sainte-Lucie',
    mf: 'Saint Martin',
    pm: 'Saint-Pierre-et-Miquelon',
    vc: 'Saint-Vincent-et-les-Grenadines',
    ws: 'Samoa',
    sm: 'Saint Marin',
    st: 'Sao Tomé et Principe',
    sa: 'Arabie Saoudite',
    sn: 'Sénégal',
    rs: 'Serbie',
    cs: 'Serbie et Monténégro',
    sc: 'les Seychelles',
    sl: 'Sierra Leone',
    sg: 'Singapour',
    sx: 'St Martin',
    sk: 'Slovaquie',
    si: 'Slovénie',
    sb: 'Les îles Salomon',
    so: 'Somalie',
    za: 'Afrique du Sud',
    gs: 'Géorgie du Sud et îles Sandwich du Sud',
    ss: 'Soudan du sud',
    es: 'Espagne',
    lk: 'Sri Lanka',
    sd: 'Soudan',
    sr: 'Suriname',
    sj: 'Svalbard et Jan Mayen',
    sz: 'Swaziland',
    se: 'Suède',
    ch: 'la Suisse',
    sy: 'République arabe syrienne',
    tw: 'Taiwan, Province of China',
    tj: 'Tadjikistan',
    tz: 'Tanzanie, République-Unie de',
    th: 'Thaïlande',
    tl: 'Timor-Leste',
    tg: 'Aller',
    tk: 'Tokelau',
    to: 'Tonga',
    tt: 'Trinité-et-Tobago',
    tn: 'Tunisie',
    tr: 'dinde',
    tm: 'Turkménistan',
    tc: 'îles Turques-et-Caïques',
    tv: 'Tuvalu',
    ug: 'Ouganda',
    ua: 'Ukraine',
    ae: 'Emirats Arabes Unis',
    gb: 'Royaume-Uni',
    us: 'États-Unis',
    um: 'Îles mineures éloignées des États-Unis',
    uy: 'Uruguay',
    uz: 'Ouzbékistan',
    vu: 'Vanuatu',
    ve: 'Venezuela',
    vn: 'Viet Nam',
    vg: 'Îles Vierges britanniques',
    vi: 'Îles Vierges américaines, États-Unis',
    wf: 'Wallis et Futuna',
    eh: 'Sahara occidental',
    ye: 'Yémen',
    zm: 'Zambie',
    zw: 'Zimbabwe'
  }
};

export default french;
