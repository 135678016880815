import ITranslation from '@/interfaces/ITranslation';

const english: ITranslation = {
  marketingQuestion: {
    default: 'Choose a source/medium',
    question: 'How did you hear about us?',
    answers: [
      {
        value: 'facebook',
        name: 'Facebook'
      },
      {
        value: 'instagram',
        name: 'Instagram'
      },
      {
        value: 'tiktok',
        name: 'TikTok'
      },
      {
        value: 'google',
        name: 'Google'
      },
      {
        value: 'tripadvisor',
        name: 'Tripadvisor'
      },
      {
        value: 'via-via',
        name: 'Word of mouth'
      },
      {
        value: 'hotel',
        name: 'Recommended by hotel'
      },
      {
        value: 'tijdschrift-krant-tv',
        name: 'Magazine/Newspaper/Television'
      },
      {
        value: 'toeristische-dienst',
        name: 'Tourist Office'
      }
    ]
  },
  buttons: {
    next: 'Proceed',
    previous: 'Return'
  },
  articlePage: {
    title: 'Select your articles',
    paragraph: '<b>Optional: <br/> Choose from the following home-made chocolate delicacies at a special rate.</b>',
    next: 'Continue without benefit'
  },
  dateTimePage: {
    legend: {
      availableDate: 'Available',
      chosenDate: 'Selected date',
      notAvailableDate: 'Not available',
      title: 'Legend'
    },
    title: 'Choose a date for your visit',
    workShop: {
      placesAvailable: '{0} places available',
      placeAvailable: '{0} place available'
    },
    times: {
      title: 'Select your timeslot',
      choose: 'Choose'
    }
  },
  errors: {
    noTimesAvailable: 'No time slots were found',
    createOrder: 'An error has occurred during the creation of your order.',
    loginDenied: 'Fill in all the information',
    repeatPassword: 'The chosen passwords do not match',
    updateData: 'A problem has occurred while updating your information.',
    emailNotAdded: 'A problem has occurred while adding this email address.',
    emailNotRemoved: 'A problem has occurred while deleting this email address.',
    gettingUsers: 'A problem has occurred while retrieving all users.',
    whoops: 'Whoops!',
    naw: {
      error: 'You have forgotten to fill in some fields. Please check all fields again before proceeding.',
      accept: 'Please accept the terms and conditions!',
      answer: 'Please answer the above question!',
      email: 'Please enter a valid email address!',
      firstName: 'Please enter a first name!',
      lastName: 'Please enter a last name!',
      acceptCorona: 'Please accept our hygiene measures!',
      country: 'Please enter your country of origin!'
    }
  },
  footer: {
    copyright: 'The Belgian Chocolate Experience'
  },
  header: {
    dateTime: 'Date/time',
    payment: 'Payment',
    personalDetails: 'Personal information',
    selectItems: 'Select items',
    round: 'Complete',
    selectTickets: 'Select tickets',
    login: 'Inloggen',
    articles: 'Chocolate-benefit'
  },
  loaders: {
    collectingAvailableTimes: 'Available timings are being retrieved',
    creatingPayment: 'Within a few seconds you will be redirected to the payment page.',
    collectingTickets: 'Your tickets are being retrieved',
    checkingPersonalDetails: 'Your information is being processed.',
    loadingHeader: 'One moment please...',
    loadingAdmin: 'Loading correct information',
    finishingOrder: 'Your order is being processed'
  },
  normalTicketPage: {
    minimumTicketSelection: 'Choose at least 1 ticket to proceed. Children under the age of 4 do not need a reservation and can go in with their companion(s).',
    title: 'Select your tickets'
  },
  paymentPage: {
    paymentOverview: {
      button: 'To payment',
      notice: 'If the payment is successful, you will receive your e-tickets in the inbox of the given email address. This may take several minutes.',
      title: 'Order summary'
    },
    title: 'Complete order'
  },
  personalDetailsPage: {
    marketing: 'Yes, I am a chocolate fan and would like to receive the latest chocolate news',
    coronaCheckBox: 'When making a reservation, visitors are asked to agree to the hygiene measures and the measures for health complaints in addition to the visiting conditions of the museum.*',
    checkBox: 'By placing this order, I agree to the <a href=\'https://www.chocolatenation.be/en/booking-conditions-tickets\' target=\'_blank\'>terms and conditions</a>*',
    email: 'Email address',
    emailRepeat: 'Email address repeat',
    firstName: 'First name',
    lastName: 'Last name',
    middleName: 'Middle name',
    notice: {
      description: 'e-tickets will be sent to the specified e-mail address. Please double check your e-mail!',
      title: 'Important notice!',
    },
    phoneNumber: 'Phone number',
    birthDay: {
      labelActive: 'Fill in your date of birth to receive something fun on your birthday',
      labelInactive: 'Date of birth - Subscribe to our newsletter to receive something fun on your birthday',
      notice: 'Yes, I am a chocolate fan and would like to receive the latest chocolate news'
    },
    title: 'Personal information',
    country: 'Country',
  },
  selectionPage: {
    nocturnesTicket: {
      description: 'These tickets get you access to Chocolate Nation including Easter brunch (excluding alcoholic drinks) at restaurant Octave.',
      title: 'Ticket Easter'
    },
    giftCardTicket: {
      description: 'You can order your gift vouchers here.',
      title: 'Gift vouchers'
    },
    normalTicket: {
      description: 'These tickets give you access to Chocolate Nation.',
      title: 'Tickets'
    },
    title: 'Choose what type of tickets you wish to order:',
    next: 'Proceed',
    workshopTicket: {
      description: 'With these tickets, you can attend a workshop and gain access to Chocolate Nation',
      title: 'Tickets including workshop',
      notice: ''
    }
  },
  shoppingCart: {
    subtitleTicket: 'ticket(s)',
    subtitleTotal: 'Total',
    emptySubtitle: 'No tickets selected!',
    emptyMessage: 'At this moment you don’t have any tickets in your shopping cart. Feel free to select tickets.',
    emptyMessageTitle: 'Shopping cart is empty!',
    title: 'My shopping cart',
    maximumAmountReached: 'You cannot choose more than {0} for this part',
    notice: {
      title: 'Added!',
      description: 'Click on the cart to open it.'
    }
  },
  ticket: {
    addButton: 'Add'
  },
  voucher: {
    button: 'APPLY',
    placeholder: 'Voucher code'
  },
  nocturnesTicketPage: {
    title: 'Select your ticket (1 ticket per person)',
    minimumTicketSelection: "<b>Select at least 1 ticket to proceed.</b><br />Museum admission + Easter brunch in Octave restaurant<br />Reception from 11:00 AM - brunch at 11:30 AM – museum visit starts from 2:00 PM"
  },
  workShopTicketPage: {
    title: 'Select your workshop',
    minimumTicketSelection: 'choose at least 1 ticket to continue',
    voucherNotValid: 'This voucher code is not valid in combination with a workshop',
    voucherOnlyValid: 'This voucher code is only valid in combination with workshop tickets',
    invalidMessage: 'Voucher code not valid',
    voucherErrorMessage: 'Couldn\'t retrieve voucher information, please try again later'
  },
  giftCardTicketPage: {
    title: 'Select your vouchers',
    minimumTicketSelection: 'Choose at least 1 ticket to proceed. Children under the age of 4 do not need a reservation and can go in with their companion(s).'
  },
  adminPage: {
    title: 'Administration panel',
    addEmail: {
      title: 'Add e-mail',
      button: 'Add',
      newEmail: 'New e-mail address',
      successMessage: '{0} is added and will automatically receive an email with login details',
      removeMessage: '{0} is verwijderd'
    },
    allEmails: {
      title: 'All e-mails',
      button: 'Delete'
    }
  },
  completePage: {
    title: 'Complete order',
    order: {
      title: 'Order',
      ticket: 'Ticket',
      amount: 'Quantity',
      price: 'Total amount'
    },
    totalPrice: 'Total:',
    dateTime: 'Date:',
    checkBox: 'I agree to place an order. Orders will be collected<br> at the end of every month and invoiced all at once.',
    button: 'Place order',
    finishOrder: {
      title: 'Complete order',
      description: 'Your order has been placed and completed. You will receive your e tickets in your inbox within 10 minutes.',
      button: 'Place new order'
    }
  },
  loginPage: {
    title: 'Log in',
    email: 'E-mail:',
    password: 'Password:',
    button: 'Log in',
    companyDetails: {
      title: 'Company information',
      contactPerson: '* Contact person',
      companyName: '* Company name',
      address: '* Address',
      zipCode: '* Postal code',
      city: '* Place',
      vatNumber: 'VAT number',
      password: {
        title: 'Password',
        password: '* New password',
        repeat: '* Repeat new password'
      },
      checkBox: 'I agree with the <a href="http://www.thebelgianchocolateexperience.be/algemenevoorwaarden" target="_blank">terms and conditions</a>',
      notice: {
        title: 'First login',
        description: 'Welcome to the Chocolate Nation business portal!<br><br> Because this is the first time you are logging into our system, you will need to fill in your company details and change your password. This is only required the first time you login.<br><br> Thank you for your understanding.',
        button: 'Save'
      }
    }
  },
  extraQuestions: {
    group_type: 'What type of group does it concern?',
    primary_school: 'Primary school',
    secondary_school: 'Secondary school',
    family_group: 'Family group',
    students_group: 'Group of students',
    friends_group: 'Group of friends',
    bachelor_group: 'Bachelor(ette) group',
    retirees_group: 'Group of retirees',
    disabilities_group: 'Group of people with disabilities',
    other: 'Other',
    disabilities: 'Are there any people in need of assistance or who have difficulties walking?',
    yes: 'Yes',
    no: 'No',
    disabilities_amount: 'How many people does it concern?',
    warning: 'NB! The group must arrive at the booked time. Not too early or too late. If this happens, unfortunately we cannot guarantee your access.'
  },
  countries: {
    choose: 'Choose a country',
    af: 'Afghanistan',
    ax: 'Aland Islands',
    al: 'Albania',
    dz: 'Algeria',
    as: 'American Samoa',
    ad: 'Andorra',
    ao: 'Angola',
    ai: 'Anguilla',
    aq: 'Antarctica',
    ag: 'Antigua and Barbuda',
    ar: 'Argentina',
    am: 'Armenia',
    aw: 'Aruba',
    au: 'Australia',
    at: 'Austria',
    az: 'Azerbaijan',
    bs: 'Bahamas',
    bh: 'Bahrain',
    bd: 'Bangladesh',
    bb: 'Barbados',
    by: 'Belarus',
    be: 'Belgium',
    bz: 'Belize',
    bj: 'Benin',
    bm: 'Bermuda',
    bt: 'Bhutan',
    bo: 'Bolivia',
    bq: 'Bonaire, Sint Eustatius and Saba',
    ba: 'Bosnia and Herzegovina',
    bw: 'Botswana',
    bv: 'Bouvet Island',
    br: 'Brazil',
    io: 'British Indian Ocean Territory',
    bn: 'Brunei Darussalam',
    bg: 'Bulgaria',
    bf: 'Burkina Faso',
    bi: 'Burundi',
    kh: 'Cambodia',
    cm: 'Cameroon',
    ca: 'Canada',
    cv: 'Cape Verde',
    ky: 'Cayman Islands',
    cf: 'Central African Republic',
    td: 'Chad',
    cl: 'Chile',
    cn: 'China',
    cx: 'Christmas Island',
    cc: 'Cocos (Keeling) Islands',
    co: 'Colombia',
    km: 'Comoros',
    cg: 'Congo',
    cd: 'Congo, Democratic Republic of the Congo',
    ck: 'Cook Islands',
    cr: 'Costa Rica',
    ci: 'Cote D\'Ivoire',
    hr: 'Croatia',
    cu: 'Cuba',
    cw: 'Curacao',
    cy: 'Cyprus',
    cz: 'Czech Republic',
    dk: 'Denmark',
    dj: 'Djibouti',
    dm: 'Dominica',
    do: 'Dominican Republic',
    ec: 'Ecuador',
    eg: 'Egypt',
    sv: 'El Salvador',
    gq: 'Equatorial Guinea',
    er: 'Eritrea',
    ee: 'Estonia',
    et: 'Ethiopia',
    fk: 'Falkland Islands (Malvinas)',
    fo: 'Faroe Islands',
    fj: 'Fiji',
    fi: 'Finland',
    fr: 'France',
    gf: 'French Guiana',
    pf: 'French Polynesia',
    tf: 'French Southern Territories',
    ga: 'Gabon',
    gm: 'Gambia',
    ge: 'Georgia',
    de: 'Germany',
    gh: 'Ghana',
    gi: 'Gibraltar',
    gr: 'Greece',
    gl: 'Greenland',
    gd: 'Grenada',
    gp: 'Guadeloupe',
    gu: 'Guam',
    gt: 'Guatemala',
    gg: 'Guernsey',
    gn: 'Guinea',
    gw: 'Guinea-Bissau',
    gy: 'Guyana',
    ht: 'Haiti',
    hm: 'Heard Island and Mcdonald Islands',
    va: 'Holy See (Vatican City State)',
    hn: 'Honduras',
    hk: 'Hong Kong',
    hu: 'Hungary',
    is: 'Iceland',
    in: 'India',
    id: 'Indonesia',
    ir: 'Iran, Islamic Republic of',
    iq: 'Iraq',
    ie: 'Ireland',
    im: 'Isle of Man',
    il: 'Israel',
    it: 'Italy',
    jm: 'Jamaica',
    jp: 'Japan',
    je: 'Jersey',
    jo: 'Jordan',
    kz: 'Kazakhstan',
    ke: 'Kenya',
    ki: 'Kiribati',
    kp: 'Korea, Democratic People\'s Republic of',
    kr: 'Korea, Republic of',
    xk: 'Kosovo',
    kw: 'Kuwait',
    kg: 'Kyrgyzstan',
    la: 'Lao People\'s Democratic Republic',
    lv: 'Latvia',
    lb: 'Lebanon',
    ls: 'Lesotho',
    lr: 'Liberia',
    ly: 'Libyan Arab Jamahiriya',
    li: 'Liechtenstein',
    lt: 'Lithuania',
    lu: 'Luxembourg',
    mo: 'Macao',
    mk: 'Macedonia, the Former Yugoslav Republic of',
    mg: 'Madagascar',
    mw: 'Malawi',
    my: 'Malaysia',
    mv: 'Maldives',
    ml: 'Mali',
    mt: 'Malta',
    mh: 'Marshall Islands',
    mq: 'Martinique',
    mr: 'Mauritania',
    mu: 'Mauritius',
    yt: 'Mayotte',
    mx: 'Mexico',
    fm: 'Micronesia, Federated States of',
    md: 'Moldova, Republic of',
    mc: 'Monaco',
    mn: 'Mongolia',
    me: 'Montenegro',
    ms: 'Montserrat',
    ma: 'Morocco',
    mz: 'Mozambique',
    mm: 'Myanmar',
    na: 'Namibia',
    nr: 'Nauru',
    np: 'Nepal',
    nl: 'Netherlands',
    an: 'Netherlands Antilles',
    nc: 'New Caledonia',
    nz: 'New Zealand',
    ni: 'Nicaragua',
    ne: 'Niger',
    ng: 'Nigeria',
    nu: 'Niue',
    nf: 'Norfolk Island',
    mp: 'Northern Mariana Islands',
    no: 'Norway',
    om: 'Oman',
    pk: 'Pakistan',
    pw: 'Palau',
    ps: 'Palestinian Territory, Occupied',
    pa: 'Panama',
    pg: 'Papua New Guinea',
    py: 'Paraguay',
    pe: 'Peru',
    ph: 'Philippines',
    pn: 'Pitcairn',
    pl: 'Poland',
    pt: 'Portugal',
    pr: 'Puerto Rico',
    qa: 'Qatar',
    re: 'Reunion',
    ro: 'Romania',
    ru: 'Russian Federation',
    rw: 'Rwanda',
    bl: 'Saint Barthelemy',
    sh: 'Saint Helena',
    kn: 'Saint Kitts and Nevis',
    lc: 'Saint Lucia',
    mf: 'Saint Martin',
    pm: 'Saint Pierre and Miquelon',
    vc: 'Saint Vincent and the Grenadines',
    ws: 'Samoa',
    sm: 'San Marino',
    st: 'Sao Tome and Principe',
    sa: 'Saudi Arabia',
    sn: 'Senegal',
    rs: 'Serbia',
    cs: 'Serbia and Montenegro',
    sc: 'Seychelles',
    sl: 'Sierra Leone',
    sg: 'Singapore',
    sx: 'Sint Maarten',
    sk: 'Slovakia',
    si: 'Slovenia',
    sb: 'Solomon Islands',
    so: 'Somalia',
    za: 'South Africa',
    gs: 'South Georgia and the South Sandwich Islands',
    ss: 'South Sudan',
    es: 'Spain',
    lk: 'Sri Lanka',
    sd: 'Sudan',
    sr: 'Suriname',
    sj: 'Svalbard and Jan Mayen',
    sz: 'Swaziland',
    se: 'Sweden',
    ch: 'Switzerland',
    sy: 'Syrian Arab Republic',
    tw: 'Taiwan, Province of China',
    tj: 'Tajikistan',
    tz: 'Tanzania, United Republic of',
    th: 'Thailand',
    tl: 'Timor-Leste',
    tg: 'Togo',
    tk: 'Tokelau',
    to: 'Tonga',
    tt: 'Trinidad and Tobago',
    tn: 'Tunisia',
    tr: 'Turkey',
    tm: 'Turkmenistan',
    tc: 'Turks and Caicos Islands',
    tv: 'Tuvalu',
    ug: 'Uganda',
    ua: 'Ukraine',
    ae: 'United Arab Emirates',
    gb: 'United Kingdom',
    us: 'United States',
    um: 'United States Minor Outlying Islands',
    uy: 'Uruguay',
    uz: 'Uzbekistan',
    vu: 'Vanuatu',
    ve: 'Venezuela',
    vn: 'Viet Nam',
    vg: 'Virgin Islands, British',
    vi: 'Virgin Islands, U.s.',
    wf: 'Wallis and Futuna',
    eh: 'Western Sahara',
    ye: 'Yemen',
    zm: 'Zambia',
    zw: 'Zimbabwe'
  }
};

export default english;
