
import { Component, Mixins } from 'vue-property-decorator';
import CartScreen from '@/components/General/Cart.vue';
import Loading from '@/components/General/Loading.vue';
import { API_ROUTES } from '@/constants/apiRoutes';
import { GETTERS } from '@/constants/getterNames';
import { MUTATIONS } from '@/constants/mutationNames';
import AXIOS from '@/utils/apiUtil';
import IError from '@/interfaces/IError';
import { ERROR_MESSAGES } from '@/constants/errorMessages';
import { API_ERROR_CODES } from '@/constants/apiErrorCodes';
import DelegateLoader from '@/mixins/DelegateLoader';

@Component({
  components: {
    CartScreen,
    Loading,
  }
})
export default class PaymentComponent extends Mixins(DelegateLoader) {

  public loading: boolean = false;

  public activeMethod: string = '';

  public checkoutUrl: string = '';

  public bank: any = 0;

  public url: string = '';

  public async mounted() {
    await this.delegate.beforeRouteEnterPayment();
  }

  public isActive(id: number) {
    return this.bank === id;
  }

  public makeActive(id: number) {
    this.bank = id;
  }

  public payOrder(issuer: string = 'ideal'): void {
    if (this.activeMethod !== 'ideal') {
      this.bank = this.activeMethod;
    }

    this.loading = true;

    AXIOS.get(`${API_ROUTES.ROUTE_PAY_ORDER}${this.$store.getters[GETTERS.GET_ORDER_ID]}?issuer=${this.bank}`)
        .then((res) => {
          if (res.status === API_ERROR_CODES.REQUEST_OK) {
            this.checkoutUrl = res.data.data.url;
            location.href = res.data.data.url;
          } else {
            this.loading = false;
            this.$store.commit(MUTATIONS.SET_ERROR, {
              code: API_ERROR_CODES.REQUEST_BAD,
              message: ERROR_MESSAGES.CREATE_ORDER_FAULT,
            } as IError);
          }
        }).catch(() => {
      this.loading = false;
      this.$store.commit(MUTATIONS.SET_ERROR, {
        code: API_ERROR_CODES.REQUEST_BAD,
        message: ERROR_MESSAGES.CREATE_ORDER_FAULT,
      } as IError);
    });
  }
}
